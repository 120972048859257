var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-add"},[(_vm.getCustomerTemplate.length == 0)?_c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"7"}},[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"templateForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"templateForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},_vm._l((_vm.templateData),function(temp,index){return _c('CRow',{key:index},[_c('CCol',{attrs:{"col":"12"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mx-2"},[_c('span',{staticClass:"d-flex align-items-baseline"},[_c('span',{staticClass:"font-weight-bold text-muted text-nowrap"},[_vm._v("Sec "+_vm._s(index + 1)+".")])]),_c('div',{staticClass:"text-center"},[(_vm.templateData.length > 1)?_c('i',{staticClass:"fa-solid fa-trash-can text-danger",on:{"click":function($event){return _vm.openDeleteModal(index)}}}):_vm._e()])]),_c('div',{staticClass:"w-100"},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CRow',[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":`lbl_template_${index}`}},[_vm._v("Template")]),_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"template_id","value":temp.template_id,"options":_vm.options && _vm.options['templates']
                                        ? _vm.options['templates']
                                        : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"change":(name, value) =>
                                        _vm.handleChangeSelect(name, value, index)}})]}}],null,true)})],1)],1)],1),_vm._l((temp.fields),function(field,sub_index){return _c('CCol',{key:sub_index,attrs:{"md":"6"}},[_c('CRow',[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":`lbl_${field}_${index}`}},[_vm._v(_vm._s(_vm._f("startCase")(field)))]),(
                                  temp.fileInputFields &&
                                  temp.fileInputFields.includes(field)
                                )?_c('CCol',{attrs:{"md":"12"}},[_c('input',{ref:`file_${index}`,refInFor:true,attrs:{"name":field,"type":"file","accept":"image/*"},on:{"change":(event) =>
                                      _vm.handleSelectedFile(field, event, index)}})]):_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextAreaInput',{attrs:{"name":field,"value":temp.data[field],"error":errors[0],"rows":3},on:{"input":(name, value) =>
                                        _vm.handleChangeInput(name, value, index)}})]}}],null,true)})],1)],1)],1)})],2)],1)])]),_c('div',{staticClass:"d-flex w-100"},[_c('hr',{class:_vm.templateData.length === index + 1 ? 'w-45' : 'w-95'}),_c('div',[(_vm.templateData.length === index + 1)?_c('a',{staticClass:"btn px-0 mx-1 text-success",attrs:{"title":"Add"},on:{"click":_vm.addOneTemplate}},[_c('i',{staticClass:"fa-solid fa-circle-plus fs-20"})]):_vm._e()]),_c('hr',{class:{ 'w-45': _vm.templateData.length === index + 1 }})])],1)}),1)]}}],null,false,577123452)})],1)],1)],1),_c('CCol',{attrs:{"md":"5"}},[_c('CCard',[_c('CCardBody',{staticClass:"preview-body"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Preview")]),_c('div',{staticClass:"preview"},_vm._l((_vm.payload),function(temp,index){return _c('div',{key:index},[_c(temp.component,{tag:"component",attrs:{"data":temp.data}})],1)}),0)])],1)],1)],1),_c('CRow',{staticClass:"justify-content-center mb-5"},[_c('CButton',{staticClass:"mx-3 btn-submit",attrs:{"color":"primary","variant":"outline","type":"button"},on:{"click":_vm.cancelCallBack}},[_vm._v("Cancel")]),_c('CButton',{staticClass:"mx-3 btn-submit",attrs:{"color":"primary","type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("Submit")])],1)],1):_c('div',[_c('PartnerView')],1),(_vm.deleteConfirmModal.isShowPopup)?_c('Modal',{attrs:{"modalTitle":_vm.deleteConfirmModal.modalTitle,"modalColor":_vm.deleteConfirmModal.modalColor,"modalContent":_vm.deleteConfirmModal.modalContent,"isShowPopup":_vm.deleteConfirmModal.isShowPopup,"buttons":_vm.deleteConfirmModal.buttons,"modalCallBack":_vm.deleteModalCallBack,"closeOnBackdrop":false}}):_vm._e(),(_vm.cropperModal.isShowPopup)?_c('CropperModal',{attrs:{"isShowPopup":_vm.cropperModal.isShowPopup,"fileData":_vm.cropperModal.fileData,"modalColor":"primary","buttonLabel":_vm.cropperModal.buttonLabel,"aspectRatio":_vm.cropperModal.aspectRatio},on:{"modalCallBack":_vm.cropperModalCallBack}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }